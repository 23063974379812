import React from "react";
import { IManagerProps, Manager } from "./components/manager";
import ApplicationContext, { defaults } from "./context/ApplicationContext";

import "./styles/bootstrap.css";

const props: IManagerProps = {
  value: "",
};

function App() {
  return (
    <div>
      <ApplicationContext.Provider value={defaults}>
        <Manager />
      </ApplicationContext.Provider>
    </div>
  );
}

export default App;
