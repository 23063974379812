import { IInstruction } from "../../types/IInstruction";
import { IReceiptRow } from "../../types/IReceiptRow";
import { IReceiptData } from "./IReceipt";

let receiptRows: IReceiptRow[] = [];
let transactionType: string = "Delivery"; //"Eat in"; // "Eat in";
let paymentType: string = "Cash";
let receiptNumber: number = 0;

export class Receipt implements IReceiptData {
  public getReceiptNumber(): number {
    receiptNumber = receiptNumber + 1;
    return receiptNumber;
  }
  public setTransactionType(value: string): void {
    transactionType = value;
  }
  getTransactionType(): string {
    return transactionType;
  }
  setPaymentType(value: string): void {
    paymentType = value;
  }
  getPaymentType(): string {
    return paymentType;
  }

  public addProductInstructions(
    rowUUID: string,
    instructions: IInstruction[]
  ): void {
    let deepCopy: IInstruction[] = JSON.parse(JSON.stringify(instructions));

    const row = receiptRows.filter((r) => r.uuid === rowUUID);

    if (row.length > 0) {
      let total = row[0].product.price;
      deepCopy.forEach((r: IInstruction) => {
        total += r.price;
      });
      row[0].price = total;
      row[0].instructions = deepCopy;
    }
  }

  public addInstructionsToLastAddedRow(instructions: IInstruction[]): void {
    let deepCopy: IInstruction[] = JSON.parse(JSON.stringify(instructions));

    const idx = receiptRows.length - 1;

    let total = receiptRows[idx].product.price;
    deepCopy.forEach((r: IInstruction) => {
      total += r.price;
    });

    receiptRows[idx].price = total;
    receiptRows[idx].instructions = deepCopy;
  }

  public addInstructions(rowId: number, instructions: IInstruction[]): void {
    let clone: IInstruction[] = JSON.parse(JSON.stringify(instructions));

    for (let i = 0; i < receiptRows.length; i++) {
      if (receiptRows[i].id === rowId) {
        receiptRows[i].instructions = clone;
        break;
      }
    }
  }

  public delete(id: string) {
    let idx = -1;

    for (let index = 0; index < receiptRows.length; index++) {
      if (receiptRows[index].uuid === id) {
        idx = index;
        break;
      }
    }

    if (idx === -1) {
      return;
    }

    receiptRows.splice(idx, 1);
  }

  public addRow(receiptRow: IReceiptRow) {
    let deepCopy: IReceiptRow = JSON.parse(JSON.stringify(receiptRow));
    receiptRows.push(deepCopy);
  }

  public getRows(): IReceiptRow[] {
    return receiptRows;
  }

  public clear() {
    receiptRows = [];
  }

  setComment(uuid: string, comment: string): void {
    for (let index = 0; index < receiptRows.length; index++) {
      if (receiptRows[index].uuid === uuid) {
        receiptRows[index].notes = comment;
        break;
      }
    }
  }
  getComment(rowId: number): string {
    return receiptRows[rowId].notes;
  }
}
