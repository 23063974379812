import { IUser } from "../../types/IUser";
import { IAuthenticatedUser } from "./IAuthenticatedUser";

let user: IUser = {
  userId: 0,
  username: "",
  password: 0,
  fullname: "",
  modules: "",
  preferences: "",
};

let token: string = "";

export class AuthenticatedUser implements IAuthenticatedUser {
  public setToken(t: string) {
    token = t;
  }
  getToken(): string {
    return token;
  }
  public setUser(u: IUser): void {
    user = u;
  }
  public getUser(): IUser {
    return user;
  }
}
