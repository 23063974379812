import { IProduct } from "../../types/IProduct";
import { IData } from "./IData";

let products: IProduct[] = [];

let selectedProductId = -1;

export class Data implements IData {
  public getProducts(): IProduct[] {
    return products;
  }
  public setProducts(p: IProduct[]) {
    products = p;
  }
  public getSelectedProductId(): number {
    return selectedProductId;
  }
  public setSelectedProductId(id: number) {
    selectedProductId = id;
  }
}
