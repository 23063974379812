import axios, { AxiosResponse } from "axios";
import { IReceiptData } from "../types/IReceiptData";
import { IKitchenData } from "../types/IKitchenData";

export const API = axios.create({
  baseURL: "https://eposapi.aleaf.co.uk/",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: () => true,
});

const baseConfig = {
  baseURL: "https://eposapi.aleaf.co.uk/",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer ",
  },
  validateStatus: () => true,
};

export const getKitchenData = (
  token: string
): Promise<IKitchenData[] | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;

  return API.get("Kitchen/Data", config)
    .then((response: AxiosResponse<IKitchenData[]>) => {
      return response.data;
    })
    .catch(handleError);
};

export const setStatus = (
  id: number,
  newStatus: number,
  token: string
): Promise<boolean | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  const d = {
    id,
    newStatus,
  };
  return API.post("Kitchen/Status", d, config)
    .then((response: AxiosResponse<boolean>) => {
      return response.data;
    })
    .catch(handleError);
};

export const setUrgency = (
  id: number,
  newUrgency: number,
  token: string
): Promise<boolean | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  const d = {
    id,
    newUrgency,
  };
  return API.post("Kitchen/Urgency", d, config)
    .then((response: AxiosResponse<boolean>) => {
      return response.data;
    })
    .catch(handleError);
};

export const createOrder = (
  data: IReceiptData,
  token: string
): Promise<number | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  return API.post("Receipt/Add", data, config)
    .then((response: AxiosResponse<number>) => {
      return response.data;
    })
    .catch(handleError);
};

function handleError(error: any) {
  console.log("api error @ " + new Date());
  console.log(error);
  console.log(error.response);

  if (error.response) {
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log("Error", error.message);
  }
}
