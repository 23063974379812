import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import ApplicationContext from "../../context/ApplicationContext";

import { getKitchenData, setStatus, setUrgency } from "../../services/Service";
import loadingImage from "../../images/loading.gif";
import {
  Button,
  Card,
  Col,
  Container,
  ListGroup,
  Modal,
  Nav,
  Row,
} from "react-bootstrap";
import { Table } from "semantic-ui-react";
import { IInstruction } from "../../types/IInstruction";
import { IVariation } from "../../types/IVariation";
import { ICommonOption } from "../../types/ICommonOption";
import { IExtra } from "../../types/IExtra";
import { IKitchenData } from "../../types/IKitchenData";
import { Login } from "../login";

export const Manager: React.FC = (): JSX.Element => {
  const context = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  const [kitchenData, setKitchenData] = useState<IKitchenData[]>([]);
  const [selectedKdId, setSelectedKdId] = useState(-1);
  const [showOptions, setShowOptions] = useState(false);

  const [showKitchen, setShowKitchen] = useState(true);
  const [showDashboard, setShowDashboard] = useState(false);
  const [showLogin, setShowLogin] = useState(true);

  const [refresh, setRefresh] = useState("");

  const [date, setDate] = useState(new Date());
  const [refreshDate, setRefreshDate] = useState(new Date());

  const [lastIdCompleted, setLastIdCompleted] = useState(0);

  async function getReceipts() {
    const response = await getKitchenData(context.AuthenticatedUser.getToken());

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    setKitchenData(response);
    setRefresh(uuidv4);
    console.log("calling the api " + new Date());
  }

  useEffect(() => {
    getReceipts();
  }, []);

  function refreshData() {
    let dif = minDifference(new Date(), refreshDate);

    if (dif > 15) {
      setRefreshDate(new Date());
      getReceipts();
      setRefresh(uuidv4());
    }
  }

  useEffect(() => {
    setRefresh(uuidv4());
    refreshData();

    window.setTimeout(() => {
      setDate(new Date());
    }, 1000);
  }, [date]);

  function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    );
  }

  function clearViews() {
    setShowDashboard(false);
    setShowKitchen(false);
    setShowLogin(false);
    setShowOptions(false);
  }

  function calculateDuration(receiptDate: Date) {
    return humanDiff(new Date(), receiptDate);
  }

  function minDifference(t1: Date, t2: Date) {
    var diff = t1.getTime() - new Date(t2).getTime();

    var msec = diff;
    var hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    var ss = Math.floor(msec / 1000);
    //msec -= ss * 1000;

    return ss;

    // return (
    //   (hh < 10 ? "0" + hh : hh) +
    //   ":" +
    //   (mm < 10 ? "0" + mm : mm) +
    //   ":" +
    //   (ss < 10 ? "0" + ss : ss)
    // );
  }

  function humanDiff(t1: Date, t2: Date) {
    var diff = t1.getTime() - new Date(t2).getTime();

    var msec = diff;
    var hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    var ss = Math.floor(msec / 1000);
    msec -= ss * 1000;

    return (
      (hh < 10 ? "0" + hh : hh) +
      ":" +
      (mm < 10 ? "0" + mm : mm) +
      ":" +
      (ss < 10 ? "0" + ss : ss)
    );
  }

  const instructions = (instructions: IInstruction[]): JSX.Element => {
    return (
      <>
        {instructions.map((data) => (
          <>
            <br />
            &emsp;&emsp;{data.description}
          </>
        ))}
      </>
    );
  };
  const variations = (v: IVariation[]): JSX.Element => {
    return (
      <>
        {v.map((data) => (
          <>
            <br />
            &emsp;&emsp;{data.description}
          </>
        ))}
      </>
    );
  };

  const extras = (e: IExtra[]): JSX.Element => {
    return (
      <>
        {e.map((data) => (
          <>
            <br />
            &emsp;&emsp;{data.description}
          </>
        ))}
      </>
    );
  };

  const common = (e: ICommonOption[]): JSX.Element => {
    return (
      <>
        {e.map((data) => (
          <>
            <br />
            &emsp;&emsp;{data.description}
          </>
        ))}
      </>
    );
  };

  function urgencyColor(value: number) {
    if (value === 0) {
      return "lightgreen";
    }
    if (value === 1) {
      return "lightsalmon";
    }
    return "blue";
  }

  function onReceiptClick(kd: IKitchenData) {
    setSelectedKdId(kd.kitchen.id);
    setShowOptions(true);
  }

  async function onNormalClick() {
    const response = await setUrgency(
      selectedKdId,
      0,
      context.AuthenticatedUser.getToken()
    );

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    status(0);
    setShowOptions(false);
  }

  async function onUrgentClick() {
    const response = await setUrgency(
      selectedKdId,
      1,
      context.AuthenticatedUser.getToken()
    );

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }
    status(0);
    setShowOptions(false);
  }

  async function status(status: number) {
    const response = await setStatus(
      selectedKdId,
      status,
      context.AuthenticatedUser.getToken()
    );

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }
    getReceipts();
    setShowOptions(false);
  }

  async function onReadyClick() {
    const response = await setStatus(
      selectedKdId,
      2,
      context.AuthenticatedUser.getToken()
    );

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }
    getReceipts();
    setShowOptions(false);
  }

  async function onCompleteClick() {
    const response = await setStatus(
      selectedKdId,
      3,
      context.AuthenticatedUser.getToken()
    );

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    setLastIdCompleted(selectedKdId);
    getReceipts();
    setShowOptions(false);
  }

  function findKitchenDataBytId(id: number) {
    return kitchenData.find((element) => {
      return element.kitchen.id === id;
    });
  }

  async function onPrintClick() {
    const kd = findKitchenDataBytId(selectedKdId);

    if (kd === undefined) {
      return;
    }

    const receiptJson = JSON.stringify(kd.receipt);
    var encodedStringBtoA = btoa(receiptJson);

    let url = "https://print.aleaf.co.uk/Default.aspx?data=";
    url = url + encodedStringBtoA;

    let defaultUrl = kd.receipt.printerSettings.defaultUrl + kd.receipt.id;

    window.location.href = url;
    window.location.href = defaultUrl; //this call should get filtered by the application its hosted in
  }

  const receiptOptions = (): JSX.Element => {
    return (
      <>
        <Modal show={showOptions} onHide={() => setShowOptions(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Options</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row" style={{ paddingTop: 10 }}>
                <div className="col">
                  <button
                    className="btn btn-success"
                    style={{ width: "100%", height: "60px", fontSize: "20px" }}
                    type="button"
                    onClick={() => onReadyClick()}
                  >
                    Ready
                  </button>
                </div>
              </div>
              <div className="row" style={{ paddingTop: 10 }}>
                <div className="col">
                  <button
                    className="btn btn-secondary"
                    style={{ width: "100%", height: "60px", fontSize: "20px" }}
                    type="button"
                    onClick={() => onNormalClick()}
                  >
                    Normal
                  </button>
                </div>
              </div>
              <div className="row" style={{ paddingTop: 10 }}>
                <div className="col">
                  <button
                    className="btn btn-warning"
                    style={{ width: "100%", height: "60px", fontSize: "20px" }}
                    type="button"
                    onClick={() => onUrgentClick()}
                  >
                    Urgent
                  </button>
                </div>
              </div>
              <div className="row" style={{ paddingTop: 10 }}>
                <div className="col">
                  <button
                    className="btn btn-outline-info"
                    style={{ width: "100%", height: "60px", fontSize: "20px" }}
                    type="button"
                    onClick={() => onPrintClick()}
                  >
                    Print
                  </button>
                </div>
              </div>
              <div className="row" style={{ paddingTop: 10 }}>
                <div className="col">
                  <button
                    className="btn btn-primary"
                    style={{ width: "100%", height: "60px", fontSize: "20px" }}
                    type="button"
                    onClick={() => onCompleteClick()}
                  >
                    Complete
                  </button>
                </div>
              </div>{" "}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowOptions(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  function viewDashboard() {
    setShowKitchen(false);
    setShowDashboard(true);
  }
  function viewKitchen() {
    setShowKitchen(true);
    setShowDashboard(false);
  }

  const template = (): JSX.Element => (
    <>
      <div className="container">
        <br />
        <Nav className="justify-content-center" activeKey="/home">
          <Button
            style={{ padding: 10, width: "200px" }}
            variant="outline-primary"
            onClick={() => viewKitchen()}
          >
            Kitchen
          </Button>
          <div style={{ padding: 5 }}> </div>
          <Button
            style={{ padding: 5, width: "200px" }}
            variant="outline-primary"
            onClick={() => viewDashboard()}
          >
            Dashboard
          </Button>
        </Nav>
      </div>

      <hr />
    </>
  );

  const kitchen = (): JSX.Element => (
    <>
      <div style={{ padding: 10 }}>
        <Row>
          {kitchenData.map((kd) =>
            kd.kitchen.status === 3 ? (
              <></>
            ) : (
              <Col>
                <Card
                  //key="Primary"
                  //text="Primary"
                  // style={{ width: "18rem" }}
                  className={kd.kitchen.status === 2 ? "bg-success mb-3" : ""}
                >
                  <Card.Body>
                    <Card.Title>
                      <Container>
                        <Row>
                          <Col>
                            <Button
                              style={{ width: "100px" }}
                              variant="outline-primary"
                              onClick={() => onReceiptClick(kd)}
                            >
                              #{kd.receipt.customerReceiptNumber}
                            </Button>
                          </Col>
                          <Col></Col>
                          <Col>{calculateDuration(kd.receipt.createdAt)}</Col>
                        </Row>
                      </Container>
                    </Card.Title>
                    <Card.Text>
                      <Table
                        unstackable
                        selectable
                        color={"blue"}
                        className={
                          kd.kitchen.status === 2
                            ? "text-white bg-success mb-3"
                            : ""
                        }
                      >
                        {/* <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Description</Table.HeaderCell>
                          <Table.HeaderCell>Qty</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header> */}
                        <Table.Body>
                          {kd.receipt.rows.map((data) => (
                            <Table.Row>
                              <Table.Cell>
                                {data.product.description}
                                {instructions(data.instructions)}
                                {variations(data.variations)}
                                {extras(data.extras)}
                                {common(data.commonOptions)}
                              </Table.Cell>
                              <Table.Cell>{data.qty}</Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </Card.Text>
                  </Card.Body>
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item>
                      <Container>
                        <Row>
                          <Col
                            style={{
                              backgroundColor: urgencyColor(kd.kitchen.urgency),
                            }}
                          >
                            Priority
                          </Col>
                          <Col></Col>
                          <Col>{kd.receipt.table}</Col>
                        </Row>
                      </Container>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </Col>
            )
          )}
        </Row>
        <br />
      </div>
    </>
  );

  function getLastCompletedReceipt() {
    return lastIdCompleted;
  }

  const dashboard = (): JSX.Element => (
    <>
      <div
        className="loading-center"
        style={{
          height: "80vh",
          fontSize: "50vh",
          color: "green",
          fontWeight: "900",
        }}
      >
        {getLastCompletedReceipt()}
      </div>
    </>
  );

  const loadingData = (): JSX.Element => {
    return (
      <>
        {context.ApplicationError.hasError() ? (
          <div
            className="loading-center"
            style={{ height: "80vh", fontSize: "30vh" }}
          >
            <h4>
              Getting data taking too long. Please wait retry in progress...
            </h4>
          </div>
        ) : (
          <div
            className="loading-center"
            style={{ height: "80vh", fontSize: "30vh" }}
          >
            <img src={loadingImage} alt="Loading" />
          </div>
        )}
      </>
    );
  };

  const handleLoginSuccess = () => {
    clearViews();
    setShowKitchen(true);
    loadingData();
  };

  const handleRegister = () => {
    clearViews();
  };
  const handleForgotPassword = () => {
    clearViews();
  };

  return (
    <div>
      <>
        <>
          {showLogin ? (
            <Login
              successful={handleLoginSuccess}
              register={handleRegister}
              forgotPassword={handleForgotPassword}
            />
          ) : (
            <>
              <>{template()}</>
              <>{receiptOptions()}</>
              <>{showDashboard ? dashboard() : <></>}</>
              <>{showKitchen ? kitchen() : <></>}</>
            </>
          )}
        </>
      </>
    </div>
  );
};
